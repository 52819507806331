import classnames from 'classnames'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import styles from './PageLayout.module.scss'
import ChannelContext from '../../context/ChannelContext'
import { THEME_NAME } from '../../interfaces/ChannelInterface'
import { NotificationInterface } from '../../services/notification/notification.types'
import AppFooter from '../AppFooter'
import AppHeader from '../AppHeader'
import MetaTags from '../MetaTags'
import NotificationContainer from '../Notification/NotificationContainer'
import Sidebar from '../Sidebar'

type Props = {
  contentClasses?: string
  notifications: NotificationInterface[]
  children: React.ReactNode
  withPaddingTop?: boolean
  hrefLangs?: true | JSX.Element[]
  loading?: boolean
}

const PageLayout: React.FC<Props> = props => {
  const [showSidebar, setShowSidebar] = useState(false)

  const sidebarOpenHandler = () => {
    if (document) {
      const html = document.querySelector('html')
      html && html.classList.add('modal-open')
    }
    setShowSidebar(true)
  }

  const sidebarCloseHandler = () => {
    if (document) {
      const html = document.querySelector('html')
      html && html.classList.remove('modal-open')
    }

    setShowSidebar(false)
  }
  return (
    <ChannelContext.Consumer>
      {({ current: channel }) => {
        const { title, description } = channel.metaTags

        if (props.loading) {
          return (
            <div
              className={classnames(styles['content'], { [styles['content--padding-top']]: props.withPaddingTop }, props.contentClasses)}
            >
              {props.children}
            </div>
          )
        }
        return (
          <>
            <MetaTags title={title} description={description} hrefLangs={props.hrefLangs} />
            <Sidebar show={showSidebar} onClose={sidebarCloseHandler} />
            <AppHeader onHamburgerClick={sidebarOpenHandler} />
            <div
              className={classnames(styles['content'], { [styles['content--padding-top']]: props.withPaddingTop }, props.contentClasses)}
            >
              {props.children}
            </div>
            <AppFooter />
            <NotificationContainer direction="top" autoHideDuration={6000} messages={props.notifications} />
          </>
        )
      }}
    </ChannelContext.Consumer>
  )
}

function mapStateToProps(store: any) {
  return {
    notifications: store.notification.messages as NotificationInterface[],
  }
}

export default connect(mapStateToProps)(PageLayout)
