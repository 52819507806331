import ls from '@livesession/sdk'
import getUserLocale from 'get-user-locale'
import _, { isArray, isEmpty } from 'lodash'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styles from './App.module.scss'
import '../../app/styles/index.module.scss'
import { useAuth } from '../../hooks/auth'
import { SubscriberInterface } from '../../hooks/events/events.types'
import * as addressApi from '../../services/address/address.api'
import { readAppStateRequest } from '../../services/app/app.actions'
import * as cartActions from '../../services/cart/cart.actions'
import { readSizeGuideSuccess } from '../../services/global/global.actions'
import { readAddressFormatsSuccess } from '../../services/locale/locale.actions'
import { readSizeGuide } from '../../services/product/product.api'
import { State } from '../../services/reducers'
import GA4Tracker from '../../services/track/track.ga4'
import GtmTracker from '../../services/track/track.gtm'
import { getThemeFromUrl } from '../../utils/channel'
import { loadLocaleData } from '../../utils/localeData'
import { _at } from '../../utils/translations'
import AppLayout from '../AppLayout'
import AppProvider from '../AppProvider/AppProvider'
import LoadinSpinner from '../LoadingSpinner'

type Props = {
  translations: Record<string, string>
  redirect?: string | false
}

const App = (props: Props) => {
  const _mounted = React.useRef<boolean>(false)
  const dispatch = useDispatch()
  const [translations, setTranslations] = React.useState<any>(props.translations)
  const [subscribers, setSubscribers] = React.useState<SubscriberInterface[]>([])
  const loadedAppState = useSelector((store: State) => store.locale?.currency?.isoCode)
  const language = useSelector((store: State) => store.locale?.language)
  const channels = useSelector((store: State) => store.global.channels)
  const user = useSelector((store: State) => store.user)
  const theme = getThemeFromUrl()
  const [lsLoaded, setLsLoaded] = React.useState<boolean>(false)
  const { isAuth, isAuthSoft, logOut } = useAuth()
  //window.APP_STATE = appState

  const fetchAddressFormats = async () => {
    try {
      const response = await addressApi.readAddressFormats()
      dispatch(readAddressFormatsSuccess(response.data))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    _mounted.current = true
    ls.init(process.env.REACT_APP_LS_ID || 'NO TRACK', {
      keystrokes: true,
    })
    if (process.env.REACT_APP_LS_DEV === 'true') {
      ls.debug()
    }
    setLsLoaded(true)
    let userLanguage = getUserLocale() || 'en-US'
    if (userLanguage.length < 3) {
      userLanguage = `${userLanguage}-${userLanguage.toUpperCase()}`
    }
    fetchAddressFormats()
    dispatch(readAppStateRequest(userLanguage))

    return () => {
      _mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const loadMomentLocales = async () => {
      await import('moment/locale/en-au')
      await import('moment/locale/en-in')
      await import('moment/locale/pl')
    }

    loadMomentLocales()

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        dispatch(cartActions.cartReadRequest())
        if (user.profile?.id && isAuthSoft()) {
          isAuth(_.noop, () => {
            logOut()
          })
        }
      }
    }
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  React.useEffect(() => {
    const loadTranslation = async () => {
      const data = await loadLocaleData(language.isoCode)
      if (_mounted.current) {
        setTranslations(data)
      }
    }
    loadTranslation()
  }, [language.isoCode])

  React.useEffect(() => {
    const fetchSizeGuide = async () => {
      try {
        const response = await readSizeGuide()
        const jsonSizes = _at('description', response.data.translations)
        const parsedData = isEmpty(jsonSizes) ? [] : JSON.parse(jsonSizes)
        dispatch(readSizeGuideSuccess(parsedData))
      } catch (e) {
        console.error(e)
      }
    }

    fetchSizeGuide()

    const trackers = [GtmTracker(process.env.REACT_APP_GTM_ID), GA4Tracker(process.env.REACT_APP_GTM_ID)]
    setSubscribers(trackers)
    trackers.forEach(s => s.init && s.init())
    return () => {
      trackers.forEach(s => s.deinit && s.deinit())
    }
  }, [])

  if (!loadedAppState || !lsLoaded || !channels) return <LoadinSpinner classes={{ spinner: styles[`app__loader--${theme}`] }} />

  return (
    <IntlProvider locale={language.isoCode} messages={translations} onError={() => {}}>
      <AppProvider redirect={props.redirect!} subscribers={subscribers}>
        <AppLayout channels={[channels.current, channels.alternate]} />
      </AppProvider>
    </IntlProvider>
  )
}

export default App
