import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import MegaMenuView from './MegaMenuView'
import { MenuItemInterface } from '../../../interfaces/MenuInterface'
import * as MenuHelper from '../Menu.helper'

type Classes = {
  root?: string
}

type Props = {
  classes?: Classes
  items: MenuItemInterface[]
  activeItem: MenuItemInterface | null
  onItemClick: (event: React.MouseEvent<HTMLElement>) => void
  selfRef?: React.RefObject<HTMLDivElement>
}

const MegaMenu = (props: Props) => {
  const wait = 200
  const { items, activeItem } = props
  const [shownLink, setShownLink] = useState<string | null>(null)
  const [isSearchBarVisible, setSearchBarVisible] = useState<boolean>()
  const activeItemIds: number[] = MenuHelper.getActiveItemIds(items, activeItem)
  const megaMenuItems: MenuItemInterface[] = MenuHelper.getMegaMenu(items)
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLLIElement>) => {
    const submenu = (event.target as HTMLLIElement).closest('#submenu')
    const link = event.target != null ? (submenu as HTMLElement)?.dataset.link : null
    timerRef.current = setTimeout(() => {
      if (link) {
        setShownLink(link)
      }
    }, wait)
  }

  const handleMenuClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    setShownLink(null)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.onItemClick != null) {
      props.onItemClick(event)
    }
    setShownLink(null)
  }

  const handleSearchIconClick = () => {
    setSearchBarVisible(true)
  }

  const handleSearchClose = () => {
    setSearchBarVisible(false)
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <MegaMenuView
      className={classNames(props.classes?.root)}
      items={megaMenuItems}
      activeItem={activeItem}
      activeItemIds={activeItemIds}
      handleItemClick={handleItemClick}
      handleMenuClose={handleMenuClose}
      handleMenuOpen={handleMenuOpen}
      shownLink={shownLink}
      isSearchBarVisible={isSearchBarVisible}
      handleSearchClose={handleSearchClose}
      handleSearchIconClick={handleSearchIconClick}
      selfRef={props.selfRef}
    />
  )
}

export default MegaMenu
