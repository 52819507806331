import React from 'react'
import styles from './AuthMenu.module.scss'
import GuestMenu from './GuestMenu'
import UserMenu from './UserMenu'
import UserContext from '../../context/UserContext'
import { useAuth } from '../../hooks/auth'

type Classes = {
  link?: string
}

type Props = {
  classes?: Classes
  miniCartRef?: React.RefObject<HTMLElement>
  selfRef?: React.RefObject<HTMLElement>
  megaMenuRef?: React.RefObject<HTMLElement>
}

const AuthMenu = (props: Props) => {
  const { isAuthSoft } = useAuth()
  const user = React.useContext(UserContext)

  return isAuthSoft(true) && user.profile ? (
    <UserMenu
      classes={{ link: props.classes?.link, list: styles['menu__list'] }}
      user={user.profile}
      selfRef={props.selfRef}
      miniCartRef={props.miniCartRef}
      megaMenuRef={props.megaMenuRef}
    />
  ) : (
    <GuestMenu classes={{ link: props.classes?.link }} />
  )
}

export default AuthMenu
