import BasketIcon from '@mui/icons-material/ShoppingBasket'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { FormattedPlural } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styles from './MiniCart.module.scss'
import MiniCartItem from './MiniCartItem'
import routes from '../../app/routes'
import ChannelContext from '../../context/ChannelContext'
import * as cartActions from '../../services/cart/cart.actions'
import { State } from '../../services/reducers'
import FormattedMessage from '../FormattedMessage'
import Link from '../Link'
import Price from '../Price'

type Props = {
  className?: string
  selfRef?: React.RefObject<HTMLElement>
  menuRef?: React.RefObject<HTMLElement>
}

const MiniCart = (props: Props) => {
  const channel = React.useContext(ChannelContext)
  const timer = React.useRef<NodeJS.Timeout | null>(null)
  const canCloseWithoutTimer = React.useRef<boolean>(false)
  const cart = useSelector((state: State) => state.cart)
  const [expanded, setExpanded] = useState<boolean>(false)
  const { selfRef, menuRef } = props
  const dispatch = useDispatch()
  const giftCounter = cart.gifts?.length || 0
  const itemCount = Number.isInteger(cart.itemCount) ? (cart.itemCount as number) : 0
  const cartItemsCounter = giftCounter > 0 ? itemCount - 1 + giftCounter : itemCount

  const handleHideNow = () => {
    canCloseWithoutTimer.current = true
    clearTimeout(timer.current!)
    close()
  }

  React.useEffect(() => {
    if (!window.location.pathname.includes('cart') && channel?.current.id === channel?.main.id) {
      dispatch(cartActions.cartReadRequest())
    }

    selfRef?.current?.addEventListener('hideNow', handleHideNow)
    return () => {
      selfRef?.current?.removeEventListener('hideNow', handleHideNow)
    }
  }, [])

  const open = () => {
    canCloseWithoutTimer.current = false
    menuRef?.current?.dispatchEvent(new Event('hideNow'))

    setExpanded(true)
  }

  const close = () => {
    if (canCloseWithoutTimer.current) {
      setExpanded(false)
      return
    }
    timer.current = setTimeout(() => {
      setExpanded(false)
    }, 3000)
  }

  const mouseOnPanel = () => {
    clearTimeout(timer.current!)
    canCloseWithoutTimer.current = true
    setExpanded(true)
  }

  const mainClass = classNames(styles['mini-cart'], props.className)
  const panelClass = classNames(styles['mini-cart__panel'], {
    [`${styles['mini-cart__panel--show']}`]: expanded,
  })
  const collapseClasses = {
    root: styles['collapse__container'],
    wrapper: styles['collapse__wrapper'],
    wrapperInner: styles['collapse__wrapper-inner'],
  }

  cart.items = _.values(cart.items)

  const itemsHtml = cart.items.map(item => <MiniCartItem cart={cart} item={item} key={item.id} gifts={cart.gifts} />)

  return !_.has(cart, 'items') || cart.items.length == 0 ? (
    <div className={mainClass} ref={(selfRef as React.RefObject<HTMLDivElement>)!}>
      <Link to={routes.cart}>
        <BasketIcon />
      </Link>
    </div>
  ) : (
    <div className={mainClass} onMouseLeave={close} ref={(selfRef as React.RefObject<HTMLDivElement>)!}>
      <Link to={routes.cart} onMouseEnter={open} className={styles['mini-cart__link']}>
        <div className={styles['link__cart']}>
          <BasketIcon />
          <span className={styles['mini-cart__counter']}>{cartItemsCounter}</span>
        </div>
      </Link>
      <div className={panelClass} onMouseEnter={mouseOnPanel}>
        <Collapse in={expanded} classes={collapseClasses} className={styles['mini-cart__panel-content']}>
          <div className={styles['mini-cart__panel--arrow']} />
          <Scrollbars
            className={styles['mini-cart__scroll-content']}
            autoHeight
            hideTracksWhenNotNeeded={false}
            autoHeightMax="calc(100% - 150px)"
          >
            <List className={styles['mini-cart__list']}>{itemsHtml}</List>
          </Scrollbars>
          <div className={styles['mini-cart__summary']}>
            <span className={styles['mini-cart__summary-ext']}>
              <FormattedPlural
                one={
                  <FormattedMessage
                    id="Cart.SummaryItem"
                    description="Cart.SummaryItem"
                    defaultMessage="{count} item"
                    values={{ count: cartItemsCounter }}
                  />
                }
                few={<FormattedMessage id="Cart.SummaryItemFew" defaultMessage="{count} items" values={{ count: cartItemsCounter }} />}
                many={<FormattedMessage id="Cart.SummaryItemsMany" defaultMessage="{count} items" values={{ count: cartItemsCounter }} />}
                other={
                  <FormattedMessage
                    id="Cart.SummaryItems"
                    description="Cart.SummaryItems"
                    defaultMessage="{count} items"
                    values={{ count: cartItemsCounter }}
                  />
                }
                value={cartItemsCounter}
              />
            </span>
            <span className={styles['mini-cart__summary-text']}>
              <FormattedMessage
                id="Cart.Subtotal"
                description="Cart.Subtotal"
                defaultMessage="Subtotal"
                className={styles['mini-cart__subtotal']}
              />
              <Price value={cart.totalItemsGross} currency={cart.currency} className={styles['mini-cart__price']} />
            </span>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              component={Link}
              role="button"
              to="/cart"
              className={styles['mini-cart__button']}
            >
              <FormattedMessage id="Cart.GoToCart" description="Cart.GoToCart" defaultMessage="My Bag" />
            </Button>
          </div>
        </Collapse>
      </div>
    </div>
  )
}
export default MiniCart
