import { defineMessages } from 'react-intl'

const messages = defineMessages({
  stepSummary: {
    id: 'CartSteps.Summary',
    defaultMessage: 'Summary',
  },
  stepAuth: {
    id: 'CartSteps.Auth',
    defaultMessage: 'Log in',
  },
  stepDelivery: {
    id: 'CartSteps.Delivery',
    defaultMessage: 'Shipping',
  },
  stepPayment: {
    id: 'CartSteps.Payment',
    defaultMessage: 'Payment',
  },
  stepConfirmation: {
    id: 'CartSteps.Confirmation',
    defaultMessage: 'Confirmation',
  },
})

export default messages
