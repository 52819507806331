import { Location } from 'history'
import _ from 'lodash'
import { ActionTypes, types } from './global.types'
import { ChannelInterface, ChannelSiteInterface } from '../../interfaces/ChannelInterface'
import { PaymentMethodInterface } from '../../interfaces/PaymentInterface'
import { SizeGuideInterface } from '../../interfaces/SizeGuideInterface'

export interface StateInterface {
  fallbackLanguageIsoCode: string
  fallbackCurrencyIsoCode: string
  loyaltyPointsConvertRate: number
  loyaltyPointsGainRate: number
  channels: {
    current: ChannelInterface
    alternate: ChannelInterface
  }
  locations: {
    [channelId: number]: {
      last?: Location
      prev?: Location
    }
  }
  paymentMethods: PaymentMethodInterface[]
  sizeGuide?: SizeGuideInterface[]
  channelSites: ChannelSiteInterface[]
}

const initialState = {
  fallbackLanguageIsoCode: 'en-AU' as string,
  fallbackCurrencyIsoCode: 'AUD' as string,
  locations: {},
} as StateInterface

export default function globalReducer(state: StateInterface = initialState, action: ActionTypes) {
  switch (action.type) {
    case types.CHANNEL_UPDATE_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.channels = action.payload
      return newState
    }

    case types.LOCATIONS_UPDATE_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.locations[action.payload.channelId] = {
        last: _.cloneDeep(action.payload.lastLocation),
        prev: _.cloneDeep(action.payload.prevLocation),
      }
      return newState
    }

    case types.READ_CONFIG_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.loyaltyPointsConvertRate = action.payload.loyaltyPointsConvertRate
      newState.loyaltyPointsGainRate = action.payload.loyaltyPointsGainRate
      newState.channels = action.payload.channels
      newState.channelSites = action.payload.channelSites

      return newState
    }

    case types.READ_SIZE_GUIDE_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.sizeGuide = action.payload
      return newState
    }

    default: {
      return state
    }
  }
}
