/* eslint-disable react/style-prop-object */
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { CurrencyInterface } from '../../interfaces/CurrencyInterface'
import { State } from '../../services/reducers'

BigNumber.config({ DECIMAL_PLACES: 2 })

interface Props {
  currency?: string
  value: string
  className?: string
  classes?: {
    root?: string
    sale?: string
    oldValue?: string
  }
  saleValue?: string | null
  negative?: boolean
}

const Price = (props: Props) => {
  const [value, setValue] = useState(0)
  const [saleValue, setSaleValue] = useState(0)

  const appCurrency = useSelector((store: State) => store.locale.currency)
  const currencies = useSelector((store: State) => store.locale.currencies)
  const currency = props.currency ? currencies[props.currency as keyof CurrencyInterface] : appCurrency

  useEffect(() => {
    setValue(new BigNumber(props.value).toNumber())
    if (props.saleValue) {
      setSaleValue(new BigNumber(props.saleValue).toNumber())
    }
  }, [props.value, props.saleValue, props.currency, appCurrency.isoCode])

  return (
    <span className={classNames(props.classes?.root!, props.className!)}>
      {saleValue ? (
        <div className={props.classes?.sale}>
          {props.negative ? '-' : null}
          {currency.sign}
          {new BigNumber(saleValue).toFixed(2)}
        </div>
      ) : null}
      <div className={saleValue ? props.classes?.oldValue : ''}>
        {props.negative ? '-' : null}
        {currency.sign}
        {new BigNumber(value).toFixed(2)}
      </div>
    </span>
  )
}

export default Price
