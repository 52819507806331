import CloseIcon from '@mui/icons-material/Close'
import classnames from 'classnames'
import React from 'react'
import styles from './HeaderMessage.module.scss'
import FormattedMessage from '../FormattedMessage'

const HeaderMessage = () => (
  <span className={styles['top-message']}>
    <FormattedMessage
      className={styles['top-message__text']}
      tag="p"
      id="Header.TopMessage"
      description="Header.TopMessage"
      defaultMessage="free express shipping when you spend $30!"
    />
  </span>
)

export default HeaderMessage
