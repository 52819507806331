import _ from 'lodash'
import { getHostname } from './link'
import { _at } from './translations'
import routes from '../app/routes'
import { resolveRoute } from '../components/Link/linkResolver'
import * as MenuHelper from '../components/Menu/Menu.helper'
import { CatalogListProductInterface } from '../interfaces/CatalogInterface'
import CategoryInterface from '../interfaces/CategoryInterface'
import ChannelInterface from '../interfaces/ChannelInterface'
import MetaTagsInterface from '../interfaces/MetaTagsInterface'
import ProductInterface, { BargainInterface, BreadcrumbInterface, SKU_GIFT } from '../interfaces/ProductInterface'

//TODO: change this to type, when NS sync will be fixed
export const isGiftVoucher = (skuCode: string) => skuCode.startsWith(SKU_GIFT)

const getProductCategory = (
  categories: CategoryInterface[],
  product: ProductInterface,
  languageIsoCode: string,
  fallbackLanguageIsoCode: string,
) => {
  const categoryId =
    _.isArray(product.breadcrumbs) && product.breadcrumbs.length > 0 ? product.breadcrumbs[product.breadcrumbs.length - 1].id : -1
  const menu = MenuHelper.getMenu({
    categories,
    locale: languageIsoCode,
    fallbackLocale: fallbackLanguageIsoCode,
  })

  return MenuHelper.getItem(menu, categoryId)
}

export const getMetaTags = (
  categories: CategoryInterface[],
  product: ProductInterface | false,
  languageIsoCode: string,
  fallbackLanguageIsoCode: string,
  channel?: ChannelInterface,
): MetaTagsInterface => {
  if (product && product.description && product.description.translations) {
    const translations = product.description.translations
    const category = getProductCategory(categories, product, languageIsoCode, fallbackLanguageIsoCode)
    const title = _at('title', translations)
    const description = _at('seoDescription', translations)
    const keywords = _at('seoKeywords', translations)

    const metaTags: MetaTagsInterface = {
      title: title || undefined,
      keywords: keywords || undefined,
      description: description || undefined,
      canonicalPath: undefined,
    }

    if (category !== null) {
      const canonicalPath = resolveRoute(routes.product, {
        categorySlug: category.slug,
        productId: product.id,
        productSlug: _at('slug', translations),
      })
      metaTags.canonicalPath = `${getHostname(channel?.canonicalDomain!)}${canonicalPath}`
    }

    return metaTags
  }

  return {}
}

export const getBreadcrumbs = (
  channel: ChannelInterface,
  product: ProductInterface,
  languageIsoCode: string,
  fallbackLanguageIsoCode: string = 'en-AU',
): BreadcrumbInterface[] => {
  const menu = MenuHelper.getMenu({
    categories: channel.categories,
    locale: languageIsoCode,
    fallbackLocale: fallbackLanguageIsoCode,
  })

  return _.cloneDeep(product.breadcrumbs || [])
    .filter(category => {
      const item = MenuHelper.getItem(menu, category.id)
      return item != null && item.id != null
    })
    .map(category => {
      const item = MenuHelper.getItem(menu, category.id)
      return {
        id: item?.id!,
        link: item?.link!,
        anchor: item?.anchor!,
        asLink: true,
      }
    })
}

export const getFullProductName = (product: ProductInterface | BargainInterface | CatalogListProductInterface) => {
  const printName = _at('printName', product.translations)
  const styleCategory = _at('styleCategory', product.translations)
  const styleName = _at('styleName', product.translations)
  const styleType = _at('styleType', product.translations)

  return `${printName || ''} ${styleCategory || ''} ${styleName || ''} ${styleType || ''}`
}
