import axios from '../../utils/axios'

export function readProduct(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}.json`,
  })
}

export function readCrossProducts(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}/crosssell.json`,
  })
}

export function readSizeGuide() {
  return axios({
    method: 'GET',
    url: '/init/size_guide.json',
  })
}
