import EventIcon from '@mui/icons-material/Event'
import '@mui/lab'
import { Box, Button, IconButton } from '@mui/material'
import { FormControlProps } from '@mui/material/FormControl'
import { TextFieldProps } from '@mui/material/TextField'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styles from './DateField.module.scss'
import useMatchMedia from '../../hooks/matchMedia'
import { FormikBag } from '../../utils/formikBag'
import FormattedMessage from '../FormattedMessage'
import TextField from '../TextField'

type InputFieldProps = TextFieldProps & {
  label?: string
  error: boolean
  helperText: any
}

type Props = {
  languageIsoCode: string
  formControlProps?: FormControlProps
  pickerProps?: Object
  dateFormat?: string
  form: FormikBag
  textFieldProps: InputFieldProps
}

const CustomActionBar = (props: any) => {
  const { isDesktop } = useMatchMedia()
  const actions = props.actions(isDesktop ? 'desktop' : 'mobile')
  return (
    <div className={styles['actions']}>
      {(actions as string[]).includes('cancel') ? (
        <Button variant="text" color="info" onClick={props.onCancel}>
          <FormattedMessage id="DateField.Calendar.Cancel" description="DateField.Calendar.Cancel" defaultMessage="Cancel" />
        </Button>
      ) : null}
      {(actions as string[]).includes('clear') ? (
        <Button variant="text" color="info" onClick={props.onClear}>
          <FormattedMessage id="DateField.Calendar.Clear" description="DateField.Calendar.Clear" defaultMessage="Clear" />
        </Button>
      ) : null}
      {(actions as string[]).includes('accept') ? (
        <Button variant="text" color="primary" onClick={props.onAccept}>
          <FormattedMessage id="DateField.Calendar.Save" description="DateField.Calendar.Save" defaultMessage="Save" />
        </Button>
      ) : null}
    </div>
  )
}

const DateField: React.FC<Props> = (props: Props) => {
  const moment = new AdapterMoment({ locale: props.languageIsoCode.toLocaleLowerCase() })
  const localMomentFormat = moment.moment.localeData().longDateFormat('L')
  const initValueIsValid = moment.moment(props.textFieldProps.value as string, localMomentFormat).isValid()
  const defaultDateValue = initValueIsValid ? moment.moment(props.textFieldProps.value as string, localMomentFormat).toString() : null

  const [dateFormat, setDateFormat] = useState<string>(props.dateFormat || localMomentFormat)
  const [dateValue, setDateValue] = useState<string | null>(defaultDateValue)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  function handleOnChange(date: any, value?: string | null | undefined) {
    let formattedDate: string = ''
    if (date !== null && !(date instanceof moment.moment)) {
      date = moment.moment(value as string, dateFormat)
      formattedDate = date.format(dateFormat).toString()
    }
    props.form.setFieldValue(props.textFieldProps.name as string, formattedDate)
    props.form.setFieldTouched(props.textFieldProps.name as string)
    setDateValue(date)
  }

  useEffect(() => {
    moment.moment.locale(props.languageIsoCode.toLocaleLowerCase())
    setDateFormat(props.dateFormat || moment.moment.localeData().longDateFormat('L'))
  }, [props.languageIsoCode])

  useEffect(() => {
    if (dateValue) {
      const dateValueFormatted = moment.moment(dateValue).format(dateFormat).toString()
      //console.log('check', dateValue, dateFormat, dateValueFormatted)
      props.form.setFieldValue(props.textFieldProps.name as string, dateValueFormatted)
      //props.form.setFieldTouched(props.textFieldProps.name as string)
    }
  }, [dateValue, props.languageIsoCode])

  let dateMask
  try {
    dateMask = dateFormat.replaceAll(/([A-z])/gm, '_')
  } catch (error: any) {
    console.log(error, dateFormat, localMomentFormat)
    dateMask = '__/__/____'
  }
  const extraProps = props.pickerProps || {}

  return (
    <LocalizationProvider dateLibInstance={moment.moment} dateAdapter={AdapterMoment} locale={props.languageIsoCode}>
      <DatePicker
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        label={props.textFieldProps.label}
        onChange={handleOnChange}
        {...extraProps}
        inputFormat={dateFormat}
        value={dateValue}
        mask={dateMask}
        componentsProps={{
          actionBar: {
            actions: (variant: string | null) => (variant === 'desktop' ? ['clear'] : ['cancel', 'clear', 'accept']),
          },
        }}
        components={{
          OpenPickerIcon: EventIcon,
          ActionBar: CustomActionBar,
        }}
        renderInput={(inputProps: any) => {
          return (
            <TextField
              formControlProps={props.formControlProps}
              name={props.textFieldProps.name}
              id={props.textFieldProps.name}
              {...inputProps}
              helperText={props.textFieldProps.helperText}
              error={props.textFieldProps.error}
              onBlur={() => props.form.setFieldTouched(props.textFieldProps.name!, true, false)}
              InputProps={{
                endAdornment: (
                  <IconButton classes={{ root: styles['calendar__button'] }} onClick={handleOpen} size="large">
                    <EventIcon />
                  </IconButton>
                ),
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}

export default DateField
