import DeleteIcon from '@mui/icons-material/DeleteOutline'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styles from './MiniCartItem.module.scss'
import messages from './MiniCartItems.messages'
import { useCart } from '../../../hooks/cart'
import { CartInterface, CartItemGiftInterface, CartItemInterface } from '../../../interfaces/CartInterface'
import { PhotosInterface } from '../../../interfaces/PhotoInterface'
import { SIZE_PRESET_NONE } from '../../../interfaces/ProductInterface'
import { getProductName, getProductRoute } from '../../../utils/catalog'
import { getFullProductName, isGiftVoucher } from '../../../utils/product'
import { _at } from '../../../utils/translations'
import Link from '../../Link'
import LoadingSpinner, { Sizes } from '../../LoadingSpinner'
import Photo from '../../Photo'
import Price from '../../Price'

type Props = {
  item: CartItemInterface
  gifts: CartItemGiftInterface[] | null
  cart: CartInterface
}

type TableItemType = {
  label: string
  content: ReactNode
  className: string
}

enum TABLE_ITEM_KIND {
  LABEL = 'label',
  CONTENT = 'content',
}

const renderTableItem = (tableItem: TableItemType, key: TABLE_ITEM_KIND) => (
  <span key={`${key}-${tableItem.label}`} className={classNames(tableItem.className, styles[`miniCart__${key}`])}>
    {tableItem[key]}
  </span>
)

const MiniCartItem: React.FC<Props> = (props: Props) => {
  const {
    item,
    gifts,
    cart,
    item: { product },
  } = props
  const intl = useIntl()
  const correctProduct = product.parent || product
  const images: PhotosInterface | string = correctProduct.description.photos || null
  const isGift = isGiftVoucher(product.parent?.skuCode!)
  const [loading, setLoading] = React.useState<boolean>(false)

  const styleCategory = _at('styleCategory', item.productInfo.translations)
  const styleName = _at('styleName', item.productInfo.translations)
  const styleType = _at('styleType', item.productInfo.translations)
  const printName = _at('printName', item.productInfo.translations)
  const productSlug = _at('slug', correctProduct.description.translations)

  const { removeProduct } = useCart()
  const to = isGift ? '/gift' : getProductRoute(correctProduct.id, productSlug)

  const remove = () => {
    if (loading) return
    setLoading(true)
    removeProduct(
      product.id,
      () => {
        setLoading(false)
      },
      () => {
        setLoading(false)
      },
    )
  }

  const name = getProductName(styleCategory, styleName, styleType)
  const tableItems: TableItemType[] = [
    {
      label: product.size?.preset !== SIZE_PRESET_NONE && product.size ? intl.formatMessage(messages.size) : '',
      content: product.size?.preset !== SIZE_PRESET_NONE && product.size ? product.size?.label! : '',
      className: styles['mini-cart__size'],
    },
    {
      label: intl.formatMessage(messages.quantity),
      content: isGift ? gifts?.length : item.quantity,
      className: styles['mini-cart__quantity'],
    },
    {
      label: intl.formatMessage(messages.price),
      content: <Price value={item.costGross} currency={cart.currency} />,
      className: styles['mini-cart__price'],
    },
  ]
  return (
    <ListItem disableGutters className={styles['mini-cart__item']}>
      <Link to={to}>
        <Photo src={images!} classes={styles['mini-cart__image']} type="catalog" alt={getFullProductName(item.productInfo)} />
      </Link>
      <div className={styles['mini-cart__details']}>
        <Link to={to} classes={{ root: styles['mini-cart__name'] }}>
          {name}
        </Link>
        <Link to={to} classes={{ root: styles['mini-cart__print-name'] }}>
          {printName}
        </Link>
        {isGiftVoucher(product.parent?.skuCode!) ? null : (
          <IconButton className={styles['mini-cart__delete']} aria-label="Remove" size="small" onClick={() => remove()}>
            {loading ? <LoadingSpinner size={Sizes.BUTTON} /> : <DeleteIcon />}
          </IconButton>
        )}
        <>{tableItems.map(tableItem => renderTableItem(tableItem, TABLE_ITEM_KIND.LABEL))}</>
        <>{tableItems.map(tableItem => renderTableItem(tableItem, TABLE_ITEM_KIND.CONTENT))}</>
      </div>
    </ListItem>
  )
}

export default MiniCartItem
