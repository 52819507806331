import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import React, { useState } from 'react'
import styles from './AppHeader.module.scss'
import routes from '../../app/routes'
import ChannelContext from '../../context/ChannelContext'
import AuthMenu from '../AuthMenu'
import FormattedMessage from '../FormattedMessage'
import HeaderMessage from '../HeaderMessage'
import Link from '../Link'
import SwitchPanel, { DISPLAY_MODE } from '../Localization/SwitchPanel'
import Menu from '../Menu'
import MiniCart from '../MiniCart'
import SearchModal from '../Search/SearchModal/SearchModal'
import ShopSwitcher from '../ShopSwitcher'

type Props = {
  onHamburgerClick: () => void
}

const AppHeader: React.FC<Props> = props => {
  const [showSearchModal, setShowSearchModal] = useState(false)
  const cartRef = React.useRef<HTMLDivElement>(null)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const megaMenuRef = React.useRef<HTMLDivElement>(null)
  const channel = React.useContext(ChannelContext).current

  return (
    <header className={styles['header']}>
      <div className={styles['header__bar']}>
        <SwitchPanel type={DISPLAY_MODE.DESKTOP} />
        <div className={styles['bar__simple-message']}>
          <FormattedMessage
            id="Header.Label.SimpleMessage"
            description="Header.Label.SimpleMessage"
            defaultMessage="Two brands, one checkout!"
          />
        </div>
        <ShopSwitcher classes={styles['bar__opposite-logo']} />
        <HeaderMessage />
      </div>
      <div className={styles['header__middle']}>
        <span className={styles['middle__hamburger']}>
          <MenuIcon classes={{ root: 'phone--visible tablet--small--visible tablet--visible' }} onClick={props.onHamburgerClick} />
        </span>
        <span className={styles['middle__logo']}>
          <Link to={routes.home}>{channel.frontLabel}</Link>
        </span>
        <div className={styles['middle__actions']}>
          <AuthMenu classes={{ link: 'desktop--visible' }} selfRef={menuRef} miniCartRef={cartRef} megaMenuRef={megaMenuRef} />
          <MiniCart selfRef={cartRef} menuRef={menuRef} />
          <SearchIcon
            classes={{ root: 'phone--visible tablet--small--visible tablet--visible' }}
            onClick={() => setShowSearchModal(true)}
          />
        </div>
      </div>
      <Menu type={DISPLAY_MODE.DESKTOP} channel={channel} classes={{ root: 'visible-pc' }} selfRef={megaMenuRef} />
      <SearchModal open={showSearchModal} onClose={() => setShowSearchModal(false)} />
    </header>
  )
}

export default AppHeader
