import React from 'react'
import { ChannelInterface, Type } from '../interfaces/ChannelInterface'

type ContextProps = {
  current: ChannelInterface
  alternate: ChannelInterface | null
  main: ChannelInterface
  opposite: ChannelInterface | null
}

const defaultChannel = {
  id: 0,
  type: Type.Main,
  code: '',
  name: '',
  domain: '',
  frontLabel: '',
  canonicalDomain: '',
  path: '',
  active: true,
  isWomenShop: true,
  theme: '',
  currency: '',
  language: {
    isoCode: '',
    shortIsoCode: '',
    name: '',
    flag: '',
  },
  country: '',
  newsletterOption: null,
  warehouse: null,
  categories: [],
  category: null,
  footerCategories: [],
  mainBanners: [],
  landingTiles: [],
  promotionItems: [],
  metaTags: {},
  seoLang: null,
  robots: null,
  params: [],
  entry: false,
}

const ChannelContext = React.createContext<ContextProps>({
  current: defaultChannel,
  alternate: null,
  main: defaultChannel,
  opposite: null,
})

export default ChannelContext
export { defaultChannel }
