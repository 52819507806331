//disable-sort-imports
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-numberformat/locale-data/pl'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/pl'

import detect from 'detect.js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import store from './services/store'
import { loadLocaleData } from './utils/localeData'
import windowErrorHandler from './utils/windowErrorHandler'
import * as appApi from './services/app/app.api'
import { AxiosResponse } from './utils/axios'
import 'intersection-observer'
import Snowfall from 'react-snowfall'
import Moment from 'moment'
import './polyfills.ts'
import PhishingAlert from './components/PhishingAlert'
import createCache from '@emotion/cache'
import PrerenderRedirect from './components/PrerenderRedirect'

const ua = detect.parse(navigator.userAgent)
window.onerror = windowErrorHandler
const osName = (ua?.os?.name || '').replace(/ /g, '')
const deviceBrand = (ua?.device?.manufacturer || '').toLowerCase()

if (osName) {
  window?.document.body.classList.add(osName.toLowerCase())
}
if (deviceBrand) {
  window?.document.body.classList.add(deviceBrand)
}
let redirecting = false
let redirect: null | AxiosResponse = null

const SNOWFLAKES_ON = !(Moment().unix() > 1672358400) //auto disable 30.12.22

const cache = createCache({
  key: 'css',
  prepend: true,
  speedy: false,
})

async function bootstrap() {
  const isoCode = 'en' //default, cna be change to navigator language
  const matchedReservedWords = [
    '/product',
    //'/collection', // - disabled as needed in WF-3392
    // '/page', //- disabled for new cms
    '/size-guide',
    '/store-locator',
    '/gift',
    // '/ft',
    // '/fk',
    '/support/order',
    '/cart',
    '/account',
  ].filter(word => window.location.pathname.startsWith(word) || window.location.pathname == '/')
  const isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') >= 0

  //at this stage it is enough to filter right url from checking
  //TODO: may request modification for friendly urls
  if (matchedReservedWords.length === 0) {
    try {
      redirect = await appApi.readRedirectFrom(window.location.pathname, window.location.hostname)
      if (redirect.data.to) {
        if (!isPrerender) {
          window.history.replaceState(redirect.data.to, redirect.data.to, redirect.data.to)
        }
        redirecting = true
      }
    } catch (error: any) {
      console.debug(error)
    }
  }

  if (isPrerender && redirecting && redirect?.data?.to) {
    // there is no need to render whole app on redirection
    ReactDOM.render(<PrerenderRedirect to={redirect.data.to} />, document.getElementById('app'))
  } else if (window?.self === window?.top) {
    ReactDOM.render(
      <Provider store={store}>
        {SNOWFLAKES_ON ? (
          <Snowfall color="#f5f5f5" style={{ zIndex: 1000, position: 'fixed' }} snowflakeCount={80} speed={[0, 2]} wind={[-1, 4]} />
        ) : null}
        <App translations={await loadLocaleData(isoCode)} redirect={redirecting && redirect ? redirect.data.to : false} />
      </Provider>,

      document.getElementById('app'),
    )
  } else {
    ReactDOM.render(<PhishingAlert />, document.getElementById('app'))
  }
}

bootstrap()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
