import React from 'react'
import styles from './SocialIcons.module.scss'

type Props = {
  openInPopup?: boolean
  url: string
  icon: React.ReactNode
}

const SocialIcon = (props: Props) => {
  const { openInPopup, url, icon } = props

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!openInPopup) {
      return
    }

    event.preventDefault()
    const height = window.screen.height / 2
    const width = window.screen.width / 2
    const left = width - width / 2
    const top = height - height / 2
    window.open(url, 'Social', `height=${height},width=${width},left=${left},top=${top}`)
  }
  return (
    <a onClick={onClick} href={url}>
      {icon}
    </a>
  )
}

export default SocialIcon
