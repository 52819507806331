import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as actions from './app.actions'
import * as api from './app.api'
import { AppStateReadRquestInterface, AppStateReadSuccessInterface, types } from './app.types'
import ChannelInterface, { Type as ChannelType } from '../../interfaces/ChannelInterface'
import { getLanguageByIsoCode } from '../../utils/locale'
import * as authActions from '../auth/auth.actions'
import * as globalActions from '../global/global.actions'
import * as localeActions from '../locale/locale.actions'

function* readAppStateRequestEvent(action: AppStateReadRquestInterface): any {
  try {
    const response = yield call(api.readAppConfig)
    const data = response.data
    const currentChannel = data.channels.find((item: ChannelInterface) => item.type === ChannelType.Main)
    const alternateChannel = data.channels.find((item: ChannelInterface) => item.type === ChannelType.Opposite)
    yield put(actions.readAppStateSuccess(data))
    yield put(
      globalActions.readConfigSuccess({
        loyaltyPointsConvertRate: data.loyaltyPointsConvertRate,
        loyaltyPointsGainRate: data.loyaltyPointsGainRate || 10,
        channels: {
          current: currentChannel,
          alternate: alternateChannel,
        },
        channelSites: data.channelEntries,
      }),
    )
    if (data.user?.tokenId) {
      yield put(
        authActions.signInSuccess({
          user: data.user,
        }),
      )
    }

    //keep this one as last! TODO: add special FLAG "isLoaded" and use it in App.tsx
    yield put(
      localeActions.readConfigSuccess({
        currencies: data.currencies,
        languages: data.languages,
        language: data.userLanguage,
        /*currency: {
          isoCode: 'AUD',
          name: 'Australian Dollar',
          sign: '$',
        }, //TODO: change to data.currency,*/
        currency: data.currency,
        addressFormats: data.addressFormat,
      }),
    )
    /*if (action.payload.languageIsoCode!) {
      yield put(localeActions.updateLanguageRequest(getLanguageByIsoCode(action.payload.languageIsoCode!)))
    }*/
  } catch (error: any) {
    console.log(error)
  }
}

function* readAppStateSuccessEvent(action: AppStateReadSuccessInterface) {
  yield all([])
}

function* readAppStateRequestListener() {
  yield takeEvery(types.READ_APP_STATE_REQUEST, readAppStateRequestEvent)
}

function* readAppStateSuccessListener() {
  yield takeEvery(types.READ_APP_STATE_SUCCESS, readAppStateSuccessEvent)
}

export default function* appSaga() {
  yield all([readAppStateRequestListener(), readAppStateSuccessListener()])
}
