import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ChannelContext from '../../context/ChannelContext'
import ProductInterface, { ProductAvailability, ProductSizeItemInterface, SIZE_PRESET_NONE } from '../../interfaces/ProductInterface'
import { State } from '../../services/reducers'

export const useProduct = () => {
  const channel = useContext(ChannelContext).current
  const [product, setProduct] = useState<ProductInterface | undefined | false>()
  const [variantId, setVariantId] = useState<number>()
  const [quantity, setQuantity] = useState<number>(0)
  const [maxQuantity, setMaxQuantity] = useState<number>(0)
  const currency = useSelector((state: State) => state.locale.currency)

  useEffect(() => {
    if (product && product?.variants && variantId) {
      for (const variant of product.variants) {
        const warehouse = variant.availability.find((_warehouse: ProductAvailability) => _warehouse.selected)
        if (variant.id === variantId && warehouse) {
          setMaxQuantity(warehouse.available)
          break
        }
      }
      // fallback, get from parent -not sure it is neede, mabye it is a dino ;)
    } else if (product && product?.id === variantId) {
      const warehouse = product?.availability.find((_warehouse: ProductAvailability) => _warehouse.selected)
      setMaxQuantity(warehouse?.available!)
    }
  }, [product, variantId, channel.id])

  const _setProduct = (_product: ProductInterface | undefined | false) => setProduct(_product)
  const _setVariantId = (_variantId: number, errorCallback?: () => void) => {
    if (product) {
      const found = product?.variants.find(item => item.id === _variantId)
      if (!found) {
        console.error('Variant not found', product?.variants)
        return errorCallback && errorCallback()
      }
      setVariantId(_variantId)
      setQuantity(0)
    }
    return null
  }
  const _setQuantity = (qty: number, errorCallback?: (maxQty: number) => void) => {
    if (qty > maxQuantity) {
      return errorCallback && errorCallback(maxQuantity)
    }

    setQuantity(qty)
    return null
  }

  const getVariant = () => {
    return product ? product?.variants.find(item => item.id === variantId!) : undefined
  }

  const getSizes = () => {
    const sizes: ProductSizeItemInterface[] = []

    product &&
      product?.variants?.forEach(variant => {
        sizes[variant.size.sortableRank] = {
          label: variant.size.label,
          productId: variant.id,
          id: variant.size.id,
          preset: variant.size.preset,
          sortableRank: variant.size.sortableRank,
          availablity: getAvailability(variant)?.available || 0,
          sizeKey: variant.size.sizeKey,
        }
      })
    return sizes
  }

  const getAvailability = (item: ProductInterface | undefined | false) => {
    if (!item) {
      return null
    }
    return item?.availability.find((warehouse: ProductAvailability) => warehouse.selected)
  }

  const getPrices = () => {
    const variant = getVariant()
    // eslint-disable-next-line no-nested-ternary
    return variant?.pricing ? variant?.pricing : product ? product?.pricing : []
  }

  const getPrice = () => {
    return getPrices()?.find(item => item.currency === currency.isoCode)
  }

  const isNoneSize = () => {
    return product ? product?.variants[0]?.size?.preset === SIZE_PRESET_NONE : false
  }

  return {
    product,
    quantity,
    maxQuantity,
    variantId,
    setProduct: _setProduct,
    setVariantId: _setVariantId,
    setQuantity: _setQuantity,
    getVariant,
    getSizes,
    getAvailability,
    getPrices,
    getPrice,
    isNoneSize,
  }
}
export default useProduct
