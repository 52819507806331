import ls from '@livesession/sdk'
import _ from 'lodash'
import { _at } from './translations'
import routes from '../app/routes'
import { Steps } from '../components/Cart/CartSteps'
import CartInterface, { CartItemInterface } from '../interfaces/CartInterface'
import { PriceInterface, ProductInterface } from '../interfaces/ProductInterface'
import {
  GTMActionFieldInterface,
  GTMAffiliation,
  GTMEventName,
  GTMProductInterface,
  GTMTransactionInterface,
  GTMEventInterface,
} from '../interfaces/GTMInterface'

const pushEvent = (item: GTMEventInterface): void => {
  if (!window.dataLayer) window.dataLayer = []
  window.dataLayer.push(item)
  const itemParams = _.omit(item, ['event', 'gtm.uniqueEventId'])
  ls.track(item.event, {
    category_str: item.event,
    action_str: itemParams[0] || 'unknown',
    ...itemParams,
  })
}

export const getGTMProductFromProduct = (
  product: ProductInterface,
  variantId: number,
  currency: string,
  list?: string,
): GTMProductInterface | undefined => {
  const variant: ProductInterface | undefined = _.find(product.variants, (_variant: ProductInterface): boolean => _variant.id === variantId)
  variant || console.error('GTM failed to determine product variant')
  if (!variant) return undefined

  const price: PriceInterface | undefined = _.find(variant.pricing, (_price: PriceInterface): boolean => _price.currency === currency)
  price || console.error('GTM failed to determine product price')

  return {
    id: variant.id,
    name: product.translations['en-AU'].name,
    price: price ? price.grossPrice : '0.00',
    variant: variant.skuCode,
    brand: '',
    category: '',
    list: list || 'Product',
  } as GTMProductInterface
}

export const getGTMProductFromCartItem = (item: CartItemInterface, list?: string): GTMProductInterface => {
  const category = `${_at('styleCategory', item.productInfo?.translations!)} ${_at('styleType', item.productInfo?.translations!)}`
  return {
    id: item.productId,
    name: item.name,
    quantity: item.quantity,
    price: item.priceGross,
    variant: item.skuCode,
    brand: '',
    category,
    list: list || 'Cart',
  } as GTMProductInterface
}

export const getGTMProductsFromCartItems = (cartItems: CartItemInterface[]): GTMProductInterface[] => {
  const products: GTMProductInterface[] = []
  for (const item of cartItems) {
    products.push(getGTMProductFromCartItem(item))
  }

  return products
}

export const getGTMTransactionFromOrder = (order: CartInterface): GTMTransactionInterface => {
  const products: GTMProductInterface[] = []
  for (const item of order.items) {
    products.push(getGTMProductFromCartItem(item, ''))
  }

  const actionField: GTMActionFieldInterface = {
    id: String(order.id),
    affiliation: GTMAffiliation.BRANDSHOP,
    revenue: order.totalGross,
    tax: order.totalTax,
    shipping: order.shippingCostGross,
    coupon: _.isEmpty(order.discounts) ? undefined : order.discounts.map(discount => discount.code).join(';'),
  }

  return {
    products,
    actionField,
  }
}

export const gtmCartAuthOptionEvent = (option: string, pathName: string) => {
  if (routes.cartAuth === pathName) {
    gtmCheckoutOptionEvent(Steps.AUTHORIZATION, option)
  }
}

export const gtmCartEvent = (quantity: number, currency: string, product?: GTMProductInterface): void => {
  const eventName: GTMEventName = quantity > 0 ? GTMEventName.PRODUCT_CART_ADD : GTMEventName.PRODUCT_CART_REMOVE
  if (!product) return
  product.quantity = Math.abs(quantity)
  const e: GTMEventInterface = {
    event: eventName,
    currencyCode: currency,
    product,
  }

  pushEvent(e)
}

export const gtmProductViewEvent = (product?: GTMProductInterface): void => {
  const eventName: GTMEventName = GTMEventName.PRODUCT_VIEW
  if (!product) return
  const e: GTMEventInterface = {
    event: eventName,
    product,
  }

  pushEvent(e)
}

export const gtmCheckoutEvent = (step: number, option: string, products: GTMProductInterface[]): void => {
  const eventName: GTMEventName = GTMEventName.CHECKOUT
  if (!products) return
  const e: GTMEventInterface = {
    event: eventName,
    step,
    option,
    products,
  }

  pushEvent(e)
}

export const gtmCheckoutOptionEvent = (step: number, option: string): void => {
  const eventName: GTMEventName = GTMEventName.CHECKOUT_OPTION

  const e: GTMEventInterface = {
    event: eventName,
    step,
    option,
  }

  pushEvent(e)
}

export const gtmTransactionConfirmEvent = (currency: string, transaction: GTMTransactionInterface): void => {
  const eventName: GTMEventName = GTMEventName.TRANSACTION_CONFIRM
  const e: GTMEventInterface = {
    event: eventName,
    currencyCode: currency,
    actionField: transaction.actionField,
    products: transaction.products,
  }

  pushEvent(e)
}

export const gtmShopSwitch = (targetShop: string) => {
  pushEvent({
    event: GTMEventName.SHOP_SWITCH,
    targetShop,
  })
}
