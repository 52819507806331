import { SubscriberInterface, SignInParams, AdjustCartParams, CartPaymentParams, ViewItemParams } from '../../hooks/events/events.types'
import CartInterface from '../../interfaces/CartInterface'
import ChannelInterface from '../../interfaces/ChannelInterface'
import { Steps } from '../../components/Cart/CartSteps/CartSteps'
import { GTMCheckoutOptions } from '../../interfaces/GTMInterface'
import {
  gtmShopSwitch,
  gtmCartAuthOptionEvent,
  gtmCheckoutOptionEvent,
  gtmCheckoutEvent,
  gtmCartEvent,
  gtmTransactionConfirmEvent,
  gtmProductViewEvent,
  getGTMProductsFromCartItems,
  getGTMTransactionFromOrder,
  getGTMProductFromCartItem,
  getGTMProductFromProduct,
} from '../../utils/gtm'

/**
 * This is legacy GTM tracker, Universal Analytics message orientd
 */
class GtmTracker implements SubscriberInterface {

  constructor(
    private trackingId: string,
  ) {}

  init = (): void => {

  }

  switchChannel = (channel?: ChannelInterface): void => {
    gtmShopSwitch(channel?.theme!)
  }

  signIn = (data: SignInParams): void  => {
    gtmCartAuthOptionEvent(GTMCheckoutOptions.SIGN_IN, data.pathname)
  }

  signUp = (data: SignInParams): void => {
    gtmCartAuthOptionEvent(GTMCheckoutOptions.SIGN_IN, data.pathname) // UA used sign_in event here
  }

  clickCheckout = (cart: CartInterface): void => {
    gtmCheckoutOptionEvent(Steps.CART, GTMCheckoutOptions.GO_TO_CHECKOUT)
  }

  viewCart = (cart: CartInterface): void => {
    gtmCheckoutEvent(Steps.CART, GTMCheckoutOptions.VIEW, getGTMProductsFromCartItems(cart.items))
  }

  purchase = (cart: CartInterface): void => {
    gtmTransactionConfirmEvent(cart.currency, getGTMTransactionFromOrder(cart))
  }

  adjustCart = (data: AdjustCartParams): void => {
    if (data.adjQty === 0) return
    if (data.item) {
      gtmCartEvent(data.adjQty, data.currency, getGTMProductFromCartItem(data.item))
    } else if (data.product && data.variantId) {
      gtmCartEvent(data.adjQty, data.currency, getGTMProductFromProduct(data.product, data.variantId, data.currency))
    }
  }

  viewItem = (data: ViewItemParams): void => {
    gtmProductViewEvent(getGTMProductFromProduct(data.product, data.product.variants[0].id, data.currency))
  }

  viewCartAuth = (cart: CartInterface): void => {
    if (cart.itemCount > 0) {
      gtmCheckoutEvent(Steps.AUTHORIZATION, GTMCheckoutOptions.VIEW, getGTMProductsFromCartItems(cart.items))
    }
  }

  viewCartShiping = (cart: CartInterface): void => {
    if (cart.itemCount > 0) {
      gtmCheckoutEvent(Steps.SHIPPING, GTMCheckoutOptions.VIEW, getGTMProductsFromCartItems(cart.items))
    }
  }

  viewCartPayment = (cart: CartInterface): void => {
    if (cart.itemCount > 0) {
      gtmCheckoutEvent(Steps.BILLING, GTMCheckoutOptions.VIEW, getGTMProductsFromCartItems(cart.items))
    }
  }

  addShippingInfo = (cart: CartInterface): void => {
    // Not implemented in UA
  }

  clickPayment = (cart: CartInterface): void => {
    gtmCheckoutOptionEvent(Steps.SHIPPING, GTMCheckoutOptions.GO_TO_PAYMENT)
  }

  addPaymentInfo = (data: CartPaymentParams): void => {
    gtmCheckoutOptionEvent(Steps.BILLING, data.method || '')
  }
}

export default (trackingId?: string): SubscriberInterface => {
  if (!trackingId) return {} as SubscriberInterface // return phantom object if no tracking id
  return new GtmTracker(trackingId)
}
