import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import ChannelContext from '../../context/ChannelContext'
import { THEME_NAME } from '../../interfaces/ChannelInterface'

const useFacebook = () => {
  const channel = useContext(ChannelContext)
  const fbq = window.fbq || _.noop
  const getCurrentPixelId = (): string | undefined => {
    return channel.current?.theme === THEME_NAME.FUNKITA ? process.env.REACT_APP_FBPIXEL_FUNKITA : process.env.REACT_APP_FBPIXEL_FUNKYTRUNKS
  }

  const pixelInit = () => {
    //fbq('init', getCurrentPixelId())
    _.noop()
  }

  const pixelPageView = () => {
    //fbq('trackSingle', getCurrentPixelId(), 'PageView')
    _.noop()
  }

  const pixelPurchase = (currency: string, value: string) => {
    //fbq('trackSingle', getCurrentPixelId(), 'Purchase', { currency, value })
    _.noop()
  }

  return { pixelInit, pixelPageView, pixelPurchase }
}

export default useFacebook
