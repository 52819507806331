import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import loadable from '@loadable/component'
import { Theme, ThemeProvider } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'
import * as Scroll from 'react-scroll'
import styles from './Channel.module.scss'
import loadTheme from './loadTheme'
import routes from '../../app/routes'
import ChannelContext from '../../context/ChannelContext'
import SocialContext from '../../context/SocialContext'
import UserContext from '../../context/UserContext'
import useFacebook from '../../hooks/fb'
import { State } from '../../services/reducers'
import LoadingSpinner from '../LoadingSpinner'
import PageLayout from '../PageLayout'

const LoadingPage = (
  <PageLayout loading>
    <LoadingSpinner margins />
  </PageLayout>
)
const Auth = loadable(() => import('../../web/views/Auth'), { fallback: LoadingPage })
const Blog = loadable(() => import('../../web/views/Blog'), { fallback: LoadingPage })
const BlogPost = loadable(() => import('../../web/views/BlogPost'), { fallback: LoadingPage })
const Cart = loadable(() => import('../../web/views/Cart'), { fallback: LoadingPage })
const CatalogPage = loadable(() => import('../../web/views/Catalog'), { fallback: LoadingPage })
const Cms = loadable(() => import('../../web/views/Cms'), { fallback: LoadingPage })
const Contact = loadable(() => import('../../web/views/Contact'), { fallback: LoadingPage })
const GiftVoucher = loadable(() => import('../../web/views/GiftVoucher'), { fallback: LoadingPage })
const GuestOrder = loadable(() => import('../../web/views/GuestOrder'), { fallback: LoadingPage })
const GuestOrderReturn = loadable(() => import('../../web/views/GuestOrderReturn'), { fallback: LoadingPage })
const GuestOrderReturnConfirmation = loadable(() => import('../../web/views/GuestOrderReturnConfirmation'), { fallback: LoadingPage })
const ProductPage = loadable(() => import('../../web/views/Product/Product'), { fallback: LoadingPage })
const ResetPassword = loadable(() => import('../../web/views/ResetPassword'), { fallback: LoadingPage })
const SearchResults = loadable(() => import('../../web/views/SearchResults'), { fallback: LoadingPage })
const SizeGuide = loadable(() => import('../../web/views/SizeGuide'), { fallback: LoadingPage })
const StoreLocator = loadable(() => import('../../web/views/StoreLocator'), { fallback: LoadingPage })
const User = loadable(() => import('../../web/views/User'), { fallback: LoadingPage })
const StaticPage = loadable(() => import('../../web/views/StaticPage'), { fallback: LoadingPage })

const LandingPage = loadable(() => import('../../web/views/LandingPage'), {
  fallback: LoadingPage,
})

const cache = createCache({
  key: 'css',
  prepend: true,
  speedy: false,
})

const Channel = (props: any) => {
  const channelRef = React.useRef<HTMLDivElement>(null)
  const user = useSelector((state: State) => state.user)
  const channel = React.useContext(ChannelContext).current
  const theme = loadTheme(channel.theme)
  const location = useLocation()
  const { pixelInit, pixelPageView } = useFacebook()

  React.useEffect(() => {
    Scroll.animateScroll.scrollToTop({ isDynamic: false, smooth: 'linear', duration: 250 })
    //window?.scrollTo(0, 0) //switch to this if iphone problems occure with scrolling to top
  }, [location.pathname])

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (window) window.document.getElementsByTagName('body')[0].style.minHeight = `${entries[0].target.clientHeight}px`
    })
    if (channelRef.current) resizeObserver.observe(channelRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [channelRef.current])

  React.useEffect(() => {
    pixelInit()
  }, [])

  React.useEffect(() => {
    pixelPageView()
  }, [location.pathname])

  return (
    <div className={classNames(styles['shop'], `${channel.theme}-theme`, 'themed')} ref={channelRef}>
      <SocialContext.Provider value={{ facebookAppId: '0' }}>
        <ThemeProvider theme={theme}>
          <CacheProvider value={cache}>
            <UserContext.Provider value={user}>
              {/* @ts-ignore TODO: mismatched types........*/}
              <Switch>
                <Route exact path={routes.search}>
                  <SearchResults />
                </Route>
                <Route exact path={routes.product}>
                  <ProductPage />
                </Route>
                <Route exact path={routes.catalog}>
                  <CatalogPage categories={channel.categories} />
                </Route>
                <Route exact path={routes.authLogin}>
                  <Auth />
                </Route>
                <Route exact path={routes.authForgotPassword}>
                  <ResetPassword />
                </Route>
                <Route exact path={routes.authForgotPasswordToken}>
                  <ResetPassword />
                </Route>
                <Route exact path={routes.sizeGuide}>
                  <SizeGuide />
                </Route>
                <Route exact path={routes.contact}>
                  <Contact />
                </Route>
                <Route exact path={routes.giftVoucher}>
                  <GiftVoucher />
                </Route>
                <Route path={routes.cmsPage}>
                  <Cms />
                </Route>
                <Route path={routes.cms}>
                  <StaticPage />
                </Route>
                <Route exact path={routes.cart}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartAuth}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartDelivery}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartPayment}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartEwayReturn}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartConfirmation}>
                  <Cart />
                </Route>
                <Route exact path={routes.cartRestore}>
                  <Cart />
                </Route>
                <Route exact path={routes.storeLocator}>
                  <StoreLocator />
                </Route>
                <Route path={routes.user}>
                  <User />
                </Route>
                <Route path={routes.guestOrder}>
                  <GuestOrder />
                </Route>
                <Route path={routes.guestOrderReturn}>
                  <GuestOrderReturn />
                </Route>
                <Route path={routes.guestReturnConfirmation}>
                  <GuestOrderReturnConfirmation />
                </Route>
                <Route path={routes.blogPost}>
                  <BlogPost />
                </Route>
                <Route path={routes.blogTag}>
                  <Blog />
                </Route>
                <Route path={routes.blog} exact>
                  <Blog />
                </Route>
                <Route exact path={routes.home}>
                  <LandingPage />
                </Route>
                <Route>
                  <LandingPage />
                </Route>
              </Switch>
            </UserContext.Provider>
          </CacheProvider>
        </ThemeProvider>
      </SocialContext.Provider>
    </div>
  )
}

export default Channel
