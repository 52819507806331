import { FormikHelpers } from 'formik'
import _ from 'lodash'
import { AXIOS_CODE_TOO_MANY_REQUESTS } from './axios'
import { intl } from './translations'
import notify from '../components/Notification/notify'

//TODO need improvements for case where we need to display errors for form with array of items (e.g many addresses in one form edit)

export function handleErrorsArray(
  errors: Object | string | string[],
  formikBag: null | FormikHelpers<any> = null,
  returnArrray?: string[],
) {
  if (_.isPlainObject(errors)) {
    errors = Object.entries(errors)
    //@ts-ignore
    for (const [k, v] of errors) {
      if (_.isArray(v) && v.length === 0) {
        continue
      }
      if (formikBag && _.has(formikBag, 'setFieldError')) {
        formikBag.setFieldError(k, v)
      }
      if (_.isArray(v) || _.isPlainObject(v)) {
        handleErrorsArray(v, formikBag, returnArrray)
      } else if (returnArrray) {
        returnArrray.push(v)
      } else notify.error(v)
    }
  } else if (_.isString(errors)) {
    if (returnArrray) {
      returnArrray?.push(errors)
    } else notify.error(errors)
  } else {
    for (const err in errors) {
      //a pretty fresh code - may add some bugs! ;)
      if (_.isNumber(parseInt(err, 10))) {
        handleErrorsArray(errors[err as keyof typeof errors] as Object, formikBag, returnArrray)
        return
      }
      // ends here

      if (_.isArray(err)) {
        err.forEach(item => {
          handleErrorsArray(item, formikBag, returnArrray)
        })
      } else {
        handleErrorsArray(err, formikBag, returnArrray)
      }
    }
  }
}

export function handleErrorResponse(
  response: Object | undefined | any,
  formikBag: null | FormikHelpers<any> = null,
  skipDefaultError?: boolean,
) {
  if (typeof response === 'undefined') {
    return
  }

  const fieldsErrors = _.get(response, 'data.fields', {})
  const globalErrors = _.get(response, 'data.global', [])
  if (response.status === AXIOS_CODE_TOO_MANY_REQUESTS) {
    notify.error(
      intl?.formatMessage({
        id: 'App.HttpError.TooManyRequests',
        defaultMessage: "You're so fast that our servers are still processing your previous action. Please wait before trying again",
      }),
    )
    return
  }

  if (!_.isEmpty(fieldsErrors) || !_.isEmpty(globalErrors)) {
    handleErrorsArray(fieldsErrors, formikBag)
    handleErrorsArray(globalErrors, formikBag)
  } else if (!skipDefaultError) {
    notify.error(
      intl?.formatMessage({
        id: 'App.UnknownError',
        defaultMessage: 'An unknown error has occurred',
      }),
    )
  }
}
