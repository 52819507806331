import { Step, StepButton, StepLabel, Stepper } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import messssages from './CartSteps.messages'
import styles from './CartSteps.module.scss'
import routes from '../../../app/routes'
import HistoryContext from '../../../context/HistoryContext'
import HistoryInterface from '../../../interfaces/HistoryInterface'

export enum Steps {
  CART = 1,
  AUTHORIZATION = 2,
  SHIPPING = 3,
  BILLING = 4,
  CONFIRMATION = 5,
}

interface Props {
  hideLabelsOnMobile?: boolean
  className?: string
  step: number
}

const CartSteps: React.FC<Props> = (props: Props) => {
  const { step } = props
  const intl = useIntl()
  const history: HistoryInterface = useContext(HistoryContext)

  const steps = [
    {
      label: intl.formatMessage(messssages.stepSummary),
      route: routes.cart,
    },
    {
      label: intl.formatMessage(messssages.stepAuth),
      route: routes.cartAuth,
    },
    {
      label: intl.formatMessage(messssages.stepDelivery),
      route: routes.cartDelivery,
    },
    {
      label: intl.formatMessage(messssages.stepPayment),
      route: routes.cartPayment,
    },
    {
      label: intl.formatMessage(messssages.stepConfirmation),
      route: null,
    },
  ]

  return (
    <Stepper activeStep={step - 1} alternativeLabel className={classNames(props.className, styles['stepper'])}>
      {steps.map((stepItr, index) => {
        const stepClick = (event: any) => {
          if (!stepItr.route) {
            return
          }
          history.push(stepItr.route as string)
        }
        return (
          <Step key={stepItr.label} classes={{ root: styles['step'] }}>
            <StepButton
              onClick={stepClick}
              disableRipple
              disableTouchRipple
              classes={{
                root: styles['step__button'],
              }}
            >
              <span className={styles['button__label']}>{stepItr.label}</span>
            </StepButton>
          </Step>
        )
      })}
    </Stepper>
  )
}
export default CartSteps
