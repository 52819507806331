export interface GTMProductInterface {
  id: string | number
  name: string // Name or ID is required.
  quantity: number
  price: string
  brand?: string
  category?: string
  variant?: string
  list?: string
}

export interface GTMActionFieldInterface {
  id: string | number
  affiliation: string
  revenue: string
  tax: string
  shipping: string
  coupon?: string
}

export interface GTMTransactionInterface {
  products: GTMProductInterface[]
  actionField: GTMActionFieldInterface
}

export interface GTMEventInterface {
  event: GTMEventName
  currencyCode?: string
  step?: number
  option?: string
  product?: GTMProductInterface
  products?: GTMProductInterface[]
  actionField?: GTMActionFieldInterface
  [key: string]: any
}

export enum GTMEventName {
  PRODUCT_CART_ADD = 'productAddToCart',
  PRODUCT_CART_REMOVE = 'productRemoveFromCart',
  PRODUCT_VIEW = 'productView',
  CHECKOUT = 'checkout',
  CHECKOUT_OPTION = 'checkoutOption',
  TRANSACTION_CONFIRM = 'transactionConfirm',
  SHOP_SWITCH = 'shopSwitch',
}
//GTMCheckoutSteps
export enum GTMCheckoutOptions {
  VIEW = 'view',
  GO_TO_CHECKOUT = 'goToCheckout',
  GO_TO_PAYMENT = 'goToPayment',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  CONTINUE_WITH_FACEBOOK = 'continueWithFacebook',
}

export enum GTMAffiliation {
  BRANDSHOP = 'Brandshop',
}
