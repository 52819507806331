import CategoryInterface from './CategoryInterface'
import { DescriptionInterface } from './DescriptionInterface'
import { ImageInterface } from './ImageInterface'
import { PhotosInterface } from './PhotoInterface'
import { TranslationsMapInterface } from './TranslationInterface'

export const SKU_GIFT = 'GIFTV' //'GIFT-VIRTUAL-PARENT-BRANDSHOP-SKU'

export enum PRODUCT_TYPE {
  PARENT = 1,
  INVENTORY = 2,
  VIRTUAL = 3, //E-VOUCHER too
  PACKAGE = 4,
  GIFT = 5,
}

export interface PriceInterface {
  currency: string
  grossPrice: string
  netPrice: string
  selected?: boolean
  taxRate: string
  taxSymbol: string
  salePriceGross?: string | null
  percentDiscountAmount?: string | null
}

export interface BargainPriceInterface {
  currency: string
  bargainGrossPrice: string
  discountAmount: string
  discountPercent: string
}

export interface ProductAvailability {
  warehouseId: number
  selected: boolean
  available: number
}

export interface ProductSizeInterface {
  id: number
  label: string
  preset: number
  sortableRank: number
  sizeKey: string
}

export type ProductSizeItemInterface = ProductSizeInterface & {
  productId: number
  availablity: number
}
export interface ProductInfoInterface {
  id: number
  skuCode: string
  type: number
  size: ProductSizeInterface | null
  translations: TranslationsMapInterface | null
  images: ImageInterface[] | null
  parent: ProductInterface | ProductInfoInterface | null
}

export interface ProductInterface {
  id: number
  skuCode: string
  barcode?: string | null
  itemTaxSchedule: number
  description: DescriptionInterface
  pricing: PriceInterface[]
  weight: number | null
  type: number
  size: ProductSizeInterface
  sizeGuidePreset: number | null
  translations: TranslationsMapInterface
  //images: ImageInterface[]
  parent: ProductInterface | null
  availability: ProductAvailability[]
  variants: ProductInterface[]
  //photos: PhotosInterface
  breadcrumbs: CategoryInterface[]
  galleryLayout: number
}

export interface BargainInterface {
  id: number
  parentId: number
  skuCode: string
  barcode?: string | null
  itemTaxSchedule: number
  description: DescriptionInterface
  pricing: PriceInterface[]
  bargainPricing: BargainPriceInterface[]
  weight: number | null
  type: number
  size: ProductSizeInterface
  sizeGuidePreset: number | null
  translations: TranslationsMapInterface
  availability: ProductAvailability[]
  breadcrumbs: CategoryInterface[]
}

export interface BreadcrumbInterface {
  id: number
  link: string
  anchor: string
  asLink: boolean
}

export const SIZE_PRESET_NONE = 9

export enum PANEL_KIND {
  SIZE_PANEL = 'SIZE_PANEL',
  REVIEWS = 'REVIEWS',
  DESCRIPTION = 'DESCRIPTON',
  STORY_PANEL = 'STORY_PANEL',
}

export type PANEL_TYPE = PANEL_KIND | undefined | false

export default ProductInterface
